const toCamelCase = (string) => {
  // eslint-disable-next-line no-param-reassign
  string = string
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, (match) => match.charAt(match.length - 1).toUpperCase());

  return string.charAt(0).toLowerCase() + string.substring(1);
};

export default toCamelCase;
