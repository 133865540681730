// Next
import Image from 'next/image';

// Contentful
import { imageLoader } from '../../helpers/contentful';

const Avatar = (props) => {
  const { image, name, width, height, sizes } = props;
  return (
    <div className="Avatar">
      <Image
        className="Avatar-image"
        layout="responsive"
        loader={imageLoader}
        sizes={sizes || '50px'}
        src={`https:${image}`}
        height={height}
        width={width}
        alt={name}
      />
    </div>
  );
};

export default Avatar;
