import generateColorModifier from '../generateColorModifier';

export default function getPlaybookCardData(props) {
  const {
    fields: { slug, colorModifiers, impactExcerpt, title, channelTags, author, figure },
  } = props;

  const data = {};

  data.slug = slug || false;
  data.modifier = colorModifiers?.[0] ? generateColorModifier('PlaybookCard', colorModifiers[0]) : false;
  data.title = title || false;
  data.subtitle = channelTags || false;

  data.excerpt = impactExcerpt?.fields ? {} : false;
  if (data.excerpt) {
    data.excerpt.prefix = impactExcerpt?.fields?.valuePrefix ? impactExcerpt.fields.valuePrefix : false;
    data.excerpt.value = impactExcerpt?.fields?.value ? impactExcerpt.fields.value : false;
    data.excerpt.suffix = impactExcerpt?.fields?.valueSuffix ? impactExcerpt.fields.valueSuffix : false;
    data.excerpt.label = impactExcerpt?.fields?.valueLabel ? impactExcerpt.fields.valueLabel : false;
  }

  data.author = author?.fields ? {} : false;
  if (data.author) {
    data.author.name = author?.fields?.fullName ? author.fields.fullName : false;
    data.author.jobtitle = author?.fields?.jobTitle ? author.fields.jobTitle : false;
    data.author.company = author?.fields?.client?.fields?.name ? author.fields?.client?.fields?.name : false;
    data.author.avatar = author?.fields?.avatar ? {} : false;
    data.author.avatar.sizes = '42px';
    data.author.avatar.image = author?.fields?.avatar?.fields?.file?.url ? author.fields.avatar.fields.file.url : false;
    data.author.avatar.width = author?.fields?.avatar?.fields?.file?.details?.image?.width
      ? author.fields.avatar.fields.file.details.image.width
      : false;
    data.author.avatar.height = author?.fields?.avatar?.fields?.file?.details?.image?.height
      ? author.fields.avatar.fields.file.details.image.height
      : false;
  }

  data.figure = figure?.[0] ? {} : false;
  if (data.figure) {
    data.figure.modifier = figure?.[0] ? generateColorModifier('PlaybookCard', `figure ${figure?.[0]}`) : false;
    data.figure.layers = [];
    data.figure.layers.push(`playbookfigure--${figure?.[0].toLowerCase()}-a`);
    data.figure.layers.push(`playbookfigure--${figure?.[0].toLowerCase()}-b`);
    data.figure.layers.push(`playbookfigure--${figure?.[0].toLowerCase()}-c`);
    if (author?.fields?.client?.fields) {
      if (colorModifiers?.[0] === 'Frost Blue' && author?.fields?.client?.fields?.logoElectricPurple) {
        data.figure.logo = author?.fields?.client?.fields?.logoElectricPurple?.fields?.file?.url;
        data.figure.width = author?.fields?.client?.fields?.logoElectricPurple?.fields?.file?.details?.image?.width;
        data.figure.height = author?.fields?.client?.fields?.logoElectricPurple?.fields?.file?.details?.image?.height;
      } else if (colorModifiers?.[0] === 'Deep Purple' && author?.fields?.client?.fields?.logoFrostBlue) {
        data.figure.logo = author?.fields?.client?.fields?.logoFrostBlue?.fields?.file?.url;
        data.figure.width = author?.fields?.client?.fields?.logoFrostBlue?.fields?.file?.details?.image?.width;
        data.figure.height = author?.fields?.client?.fields?.logoFrostBlue?.fields?.file?.details?.image?.height;
      } else if (author?.fields?.client?.fields?.logoWhite) {
        data.figure.logo = author?.fields?.client?.fields?.logoWhite?.fields?.file?.url;
        data.figure.width = author?.fields?.client?.fields?.logoWhite?.fields?.file?.details?.image?.width;
        data.figure.height = author?.fields?.client?.fields?.logoWhite?.fields?.file?.details?.image?.height;
      }
    }
  }

  return Object.keys(data).length ? data : false;
}
