// Hooks
import { useEffect, useRef, useState } from 'react';

// GSAP
import { gsap } from 'gsap';

// utils
import isTouchDevice from '../../utils/isTouchDevice';

const PlaybookExcerpt = (props) => {
  const { prefix, value, suffix, label, isHovering } = props;

  const [wrapperWidth, setWrapperWidth] = useState(0);

  const valueRef = useRef();
  const wrapperRef = useRef();

  const incrementValue = (durationValue, startValue, endValue) => {
    const tween = { start: startValue, end: 0 };
    gsap.to(tween, {
      duration: durationValue,
      end: endValue,
      onUpdate: () => {
        if (valueRef.current) valueRef.current.innerHTML = tween.end.toFixed(0);
      },
    });
  };

  useEffect(() => {
    if (isHovering && !isTouchDevice()) {
      const duration = 0.66;
      const startValue = 0;
      const endValue = parseInt(valueRef.current.getAttribute('data-value'), 10);
      setWrapperWidth(wrapperRef.current.offsetWidth);
      incrementValue(duration, startValue, endValue);
    }
  }, [isHovering]);

  return (
    <span className="PlaybookExcerpt">
      <span className="PlaybookExcerpt-wrapper" style={{ minWidth: `${wrapperWidth}px` }} ref={wrapperRef}>
        {prefix && <span className="PlaybookExcerpt-prefix">{prefix}</span>}
        {value && (
          <span className="PlaybookExcerpt-value" ref={valueRef} data-value={value}>
            {value}
          </span>
        )}
        {suffix && <span className="PlaybookExcerpt-suffix">{suffix}</span>}
      </span>
      {label && <span className="PlaybookExcerpt-label">&nbsp;{label}</span>}
    </span>
  );
};

export default PlaybookExcerpt;
