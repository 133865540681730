// Next
import Link from 'next/link';

// Hooks
import { useRef, useEffect, useState } from 'react';

// GSAP
import { gsap, Power2 } from 'gsap';

// Analytics
import { analyticsClient } from '../../client/analytics';

// Utils
import isTouchDevice from '../../utils/isTouchDevice';

// Components
import Author from '../Author/Author';
import PlaybookExcerpt from '../PlaybookExcerpt/PlaybookExcerpt';

const PlaybookCard = (props) => {
  const { subtitle, excerpt, title, slug, author, modifier, figure } = props;
  const [isHovering, setIsHovering] = useState(false);

  const logoRef = useRef();
  const animateLogo = ({ delay, orLeft = '', orRight = '', dLeft = '', dRight = '', dX }) => {
    if (isHovering) {
      gsap.to(logoRef.current, { duration: 0.45, delay, left: dLeft, right: dRight, x: dX, ease: Power2.easeOut });
    } else {
      gsap.to(logoRef.current, { duration: 0.45, delay, left: orLeft, right: orRight, x: 0, ease: Power2.easeOut });
    }
  };
  useEffect(() => {
    if (!isTouchDevice()) {
      switch (figure?.modifier) {
        case 'PlaybookCard--figureOne':
          animateLogo({ delay: 0.14, orLeft: '7.5%', dLeft: '100%', dX: '-100%' });
          break;
        case 'PlaybookCard--figureTwo':
          animateLogo({ delay: 0.1, orRight: '7.5%', dRight: '100%', dX: '100%' });
          break;
        case 'PlaybookCard--figureThree':
          animateLogo({ delay: 0.16, orRight: '7.5%', dRight: '80%', dX: '80%' });
          break;
        default:
          break;
      }
    }
    return () => {
      if (logoRef.current) {
        gsap.killTweensOf(logoRef.current);
      }
    };
  }, [isHovering]);

  const ref = useRef();
  useEffect(() => {
    analyticsClient.trackLink(ref.current, '[Homepage] Playbook CTA clicked', {
      slug,
    });
  }, []);

  return (
    <Link href={`/playbooks/${slug}`}>
      <a
        className={`PlaybookCard ${figure?.modifier || ''} ${modifier || ''}`}
        ref={ref}
        onMouseEnter={() => setIsHovering(true)}
        onFocus={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="PlaybookCard-inner">
          <div className="PlaybookCard-header">
            {subtitle && <div className="PlaybookCard-tag">{subtitle[0]}</div>}
            {excerpt && <PlaybookExcerpt {...excerpt} isHovering={isHovering} />}
          </div>
          {title && <h3 className="PlaybookCard-title">{title}</h3>}
          {author && <Author {...author} modifier="Author--playbookCard" />}
          {figure && (
            <div className="PlaybookCard-figure">
              {figure.layers.map((layer, index) => (
                <svg className="PlaybookCard-figureLayer" key={`PlaybookCard-figureLayer--${index}`}>
                  <use xlinkHref={`#${layer}`} />
                </svg>
              ))}
              <img
                className="PlaybookCard-figureLogo"
                alt={`Logo ${author.company}`}
                loading="lazy"
                src={figure.logo}
                ref={logoRef}
              />
            </div>
          )}
        </div>
      </a>
    </Link>
  );
};

export default PlaybookCard;
