// Components
import Avatar from '../Avatar/Avatar';

const Author = (props) => {
  const { avatar, name, jobtitle, modifier, sizes } = props;

  return (
    <div className={`Author ${modifier || ''}`}>
      {avatar && (
        <div className="Author-avatar">
          <Avatar
            image={avatar.image}
            width={avatar.width}
            height={avatar.height}
            name={name}
            sizes={sizes || '(min-width: 1024px) 60px, 48px'}
          />
        </div>
      )}
      {name && (
        <div className="Author-copy">
          {name && <span className="Author-name">{name}</span>}
          {jobtitle && <span className="Author-info">{jobtitle}</span>}
        </div>
      )}
    </div>
  );
};

export default Author;
